import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react'
import emailjs from 'emailjs-com'


function Contact() {
	const form = useRef();

	const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ax6or19', 'template_d4rtxw1', form.current, 'KVh5u0BHXLgQBVehz')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
		e.target.reset();
  };
	return (
		<section id='contact'>
			<h5>Get In Touch</h5>
			<h2>Contact Me</h2>

			<div className="container contact__container">
				<div className="contact__options">
					<article className="contact__option">
						<MdOutlineEmail className='contact__option-icon'/>
						<h4>Email</h4>
						<h5>dummyegator@gmail.com</h5>
						<a href="mailto:dummyegator@gmail.com" target='_blank' rel="noreferrer">Send a message</a>
					</article>
					<article className="contact__option">
						<RiMessengerLine className='contact__option-icon'/>
						<h4>Messenger</h4>
						<h5>egatortutorials</h5>
						<a href="https://m.me/ernest.achiever target='_blank'">Send a message</a>
					</article>
					<article className="contact__option">
						<BsWhatsapp className='contact__option-icon'/>
						<h4>WhatsApp</h4>
						<h5>+123456789</h5>
						<a href="https://api.whatsapp.com/send?phone=+23355733888" target='_blank' rel="noreferrer">Send a message</a>
					</article>
				</div>
				<form ref={form} onSubmit={sendEmail}>
					<input type="text" name='name' placeholder='Your Full Name' required />
					<input type="email" name='email' placeholder='Your Email' required />
					<textarea name="message" rows="7" placeholder='Your Message' required></textarea>
					<button type="submit" className='btn btn-primary'>Send Message</button>
				</form>
			</div>
		</section>
	)
}

export default Contact