import React from 'react'
import './footer.css'
import {FaGithub} from 'react-icons/fa'

function Footer() {
	return (
		<footer>
			<a href="#" className='footer__logo'>TimaBor</a>

			<ul className='permalinks'>
				<li><a href="#">Home</a></li>
				<li><a href="#about">About</a></li>
				<li><a href="#experience">Experience</a></li>
				<li><a href="#services">Services</a></li>
				<li><a href="#portfolio">Portfolio</a></li>
				<li><a href="#testimonials">Testimonials</a></li>
				<li><a href="#contact">Contact</a></li>
			</ul>

			<div className="footer__socials">
				<a href="https://github.com"><FaGithub/></a>
			</div>

			<div className="footer__copyright">
				<small>&copy; TimaBor. All right reserved.</small>
			</div>
		</footer>
	)
}

export default Footer