import React from 'react'
import AVA1 from '../../assets/avatar1.jpg'
import AVA2 from '../../assets/avatar2.jpg'
import AVA3 from '../../assets/avatar3.jpg'
import AVA4 from '../../assets/avatar4.jpg'
import './testimonials.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation} from 'swiper';

import 'swiper/css'
import "swiper/css/pagination"

const data = [
	{
		avatar: AVA1,
		name: 'Tina Show',
		review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae, odio qui? Aliquam iure hic tempora asperiores culpa? Perferendis ullam quibusdam minima optio, quo alias, reiciendis atque vitae pariatur, blanditiis quasi.'
	},
	{
		avatar: AVA2,
		name: 'Shatta Wale',
		review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae, odio qui? Aliquam iure hic tempora asperiores culpa? Perferendis ullam quibusdam minima optio, quo alias, reiciendis atque vitae pariatur, blanditiis quasi.'
	},
	{
		avatar: AVA3,
		name: 'Kwame Despite',
		review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae, odio qui? Aliquam iure hic tempora asperiores culpa? Perferendis ullam quibusdam minima optio, quo alias, reiciendis atque vitae pariatur, blanditiis quasi.'
	},
	{
		avatar: AVA4,
		name: 'Nana Ama McBrown',
		review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae, odio qui? Aliquam iure hic tempora asperiores culpa? Perferendis ullam quibusdam minima optio, quo alias, reiciendis atque vitae pariatur, blanditiis quasi.'
	}
]

function Testimonials() {
	return (
		<section id='testimonials'>
			<h5>Review from clients</h5>
			<h2>Testimonials</h2>

			<Swiper className="container testimonials__container"
			modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
				{
					data.map(({avatar, name, review}, index) => {
						return (
							<SwiperSlide key={index} className="testimonial">
								<div className="client__avatar">
									<img src={avatar} alt={name} />
								</div>
								<h5 className='client__name'>{name}</h5>
								<small className="client__review">{review}</small>
							</SwiperSlide>
						)
					})
				}
			</Swiper>
		</section>
	)
}

export default Testimonials