import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'
import {useState} from 'react'

function Nav() {
	const [activeNav, setActiveNav] = useState('#');
	return (
		<nav>
			<a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? '_active' : ''}><AiOutlineHome/></a>
			<a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? '_active' : ''}><AiOutlineUser/></a>
			<a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? '_active' : ''}><BiBook/></a>
			<a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? '_active' : ''}><RiServiceLine/></a>
			<a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? '_active' : ''}><BiMessageSquareDetail/></a>
		</nav>
	)
}

export default Nav